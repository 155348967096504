import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PasswordFormI} from '@components/password-creation';
import {loader} from '@modules/custom-loader/models/decorators';
import {NotificationService} from '@modules/notification';
import {NotificationType} from '@modules/notification/models/notification.model';
import {PermissionsService} from '@modules/permissions';
import {Observable, of, Subject, throwError} from 'rxjs';
import {catchError, shareReplay, switchMap, tap, throttleTime} from 'rxjs/operators';

import {AppStateService} from '@core/modules';
import {AppRouterService} from '@core/services';
import {AuthBehavior, AuthDto, LogOutConfigDto, TokenDto} from '@models/auth';
import {ResponseDto, StatusOptions} from '@models/response';

import {GtmService} from '../gtm/gtm.service';
import {UserService} from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private onCheckUser$: Subject<AuthBehavior | null>;
  private onLogOut$: Subject<void>;

  get needChangePassword$(): Observable<boolean> {
    return this.appStateService.needChangePassword$;
  }

  get isPasswordSet$(): Observable<boolean> {
    return this.appStateService.isPasswordSet$;
  }

  get isAuthorized(): boolean {
    return !!this.appStateService.token;
  }

  private readonly gtmService = inject(GtmService);

  constructor(
    private readonly appStateService: AppStateService,
    private readonly userService: UserService,
    private readonly appRouterService: AppRouterService,
    private readonly permissionsService: PermissionsService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
  ) {
  }

  updateUser(behavior: AuthBehavior | null = null): void {
    if (!this.onCheckUser$ || this.onCheckUser$.isStopped) {
      return;
    }
    this.onCheckUser$.next(behavior);
  }

  onCheckUser(): Observable<AuthDto | null> {
    return this.onCheckUser$.pipe(
      throttleTime(1000),
      switchMap((behavior) => this.getCurrentUser(behavior)),
      shareReplay(1),
    );
  }

  @loader()
  verifyUser(hash: string, navigate = true, companyId: string | null = null): Observable<AuthDto | null> {
    return this.userService.registrationVerification({hash, companyId}).pipe(
      tap((response) => {
        this.gtmService.pushEvent({event: 'successful_verification'});
        this.setCurrentUser(response, navigate);
      }),
      catchError(err => {
        if (err.errors.statusOptions === StatusOptions.USER_COMPANY_NOT_VERIFIED) {
          return of(null);
        }
        return throwError(err);
      }),
    );
  }

  private getCurrentUser(behavior: AuthBehavior | null): Observable<AuthDto | null> {
    return this.userService.getCurrentUser(behavior).pipe(
      tap(response => {
        this.setCurrentUser(response);
      }),
    );
  }

  setCurrentUser(data: AuthDto | null, navigate = false, returnUrl?: string): AuthDto | null {
    if (!data) {
      return null;
    }
    const {invitationsToBoat, user} = data;
    this.appStateService.setAuth(data);
    this.appStateService.invitation = invitationsToBoat || null;
    if (user.needChangePassword) {
      this.appRouterService.changePassword();
      return data;
    }
    if (navigate) {
      if (returnUrl) {
        this.router.navigateByUrl(returnUrl);
      } else {
        const {isConnectedToBoats, needRedirectToCv, supplierRedirect, oneBoatId} = data;
        this.appRouterService.navigateToStartPageByRole(isConnectedToBoats, needRedirectToCv, supplierRedirect, oneBoatId);
      }
    }
    return data;
  }

  logoutUser(forcedLogout = false, navigate = true, config: LogOutConfigDto | null = null): void {
    if (!this.appStateService.isAuthenticated) {
      return;
    }
    const onLogout = this.onLogOut$;
    if (onLogout && !onLogout.isStopped) {
      onLogout.next();
    }
    this.appStateService.clearUserData();
    const isBrowser = this.appStateService.isBrowser;
    if (!isBrowser) {
      this.appStateService.forcedLogout = forcedLogout;
    }

    if (navigate) {
      this.appRouterService.navigateToLogin();
    }

    if (config) {
      const type = forcedLogout ? NotificationType.INFO : NotificationType.ERROR;
      const {message, timeout, translate} = config;
      this.notificationService.openByType(message, type, timeout, translate);
    }
  }

  updatePassword(payload: Exclude<PasswordFormI, 'passwordRepeat'>): Observable<ResponseDto<TokenDto>> {
    return this.userService.updateUserPassword(payload).pipe(
      tap(response => {
        const data = response.data;
        if (data.token) {
          this.appStateService.token = data.token;
        }
      }),
    );
  }

  setPassword(payload: Exclude<PasswordFormI, 'passwordRepeat'>): Observable<ResponseDto<TokenDto>> {
    return this.userService.setUserPassword(payload).pipe(
      tap(response => {
        const data = response.data;
        if (data.token) {
          this.appStateService.token = data.token;
        }
      }),
    );
  }

  logout(): Observable<ResponseDto> {
    return this.onLogOut$.pipe(
      switchMap(() => this.userService.logout()),
    );
  }

  clearUserData(): void {
    this.appStateService.clearUserData();
  }

  needChangePassword(needChange: boolean): void {
    this.appStateService.needChangePassword = needChange;
  }

  init(): void {
    this.onCheckUser$ = new Subject<AuthBehavior | null>();
    this.onLogOut$ = new Subject<void>();
  }

  destroy(): void {
    this.onCheckUser$.complete();
    this.onLogOut$.complete();
  }
}
